<template>
  <div class="comservices">
    <!-- <div style="position: relative;height:2.6rem">
      <img
        class="bannerImg"
        src="../assets/images/main_business/banner2.png"
        alt=""
      />
      <div class="banner">
        <p class="title">AI周边产品</p>
        <p>
         Extrobox系列是南京中科逆熵科技有限公司基于寒武纪MLU220智能芯片开发的边缘端AI智能盒子。适用于智慧交通、智慧安防、智慧楼宇、智慧校园等多种应用场景 </p>
      </div>
       
    </div> -->
    <div class="banner-img background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/main_business/banner2.png')})`, 'background-size':'cover'}">
        <div class="banner">
            <p class="title" style="font-weight: 600;position: relative;bottom:0.2rem">AI周边产品</p>
            <div class="banner-smallTitle"> Extrobox系列是南京中科逆熵科技有限公司基于寒武纪MLU220智能芯片开发的边缘端AI智能盒子。适用于智慧交通、智慧安防、智慧楼宇、智慧校园等多种应用场景 </div>
        </div>
    </div>
    <div class="swiper-banner">
        <div class="swiper">
            <div class="tabs">
                <div :class="changeTabs==1?'tabs-actived':'tabs-active'" @click="changeTabs=1"><div class="text">ExtroBox201</div></div>
                <div :class="changeTabs==2?'tabs-actived':'tabs-active'" @click="changeTabs=2"><div class="text">ExtroBox202</div></div>
                <div :class="changeTabs==3?'tabs-actived':'tabs-active'" @click="changeTabs=3"><div class="text">ExtroBox210</div></div>
            </div>
            <div class="swiper1" v-if="changeTabs==1">
                <img src="../assets/images/main_business/box201.png" alt="" class="swiper-img">
                <div class="swiper-right"> 
                    <div class="swiper-right-title">ExtroBox <span style="color:#333333">201</span></div>
                    <div class="swiper-right-cont">Extrobox201是南京中科逆熵科技有限公司基于寒武纪MLU220智能芯片开发的一款边缘端AI智能盒子。该平台采用X86主控与M.2卡相结合的形式，具有8Tops的强大算力，可直接运行各类深度学习算法，并进行图像的实时处理等。</div>
                </div>
            </div>
            <div class="swiper1" v-if="changeTabs==2">
                <img src="../assets/images/main_business/box202.png" alt="" class="swiper-img">
                <div class="swiper-right"> 
                    <div class="swiper-right-title">ExtroBox <span style="color:#333333">202</span></div>
                    <div class="swiper-right-cont">Extrobox202是南京中科逆熵科技有限公司基于寒武纪MLU220智能芯片开发的一款边缘端AI智能盒子。该平台采用X86主主控与M.2卡相结合的形式，具有8Tops的强大算力，可直接运行各类深度学习算法，并进行图像的实时处理等。</div>
                </div>
            </div>
            <div class="swiper1" v-if="changeTabs==3">
                <img src="../assets/images/main_business/box210.png" alt="" class="swiper-img">
                <div class="swiper-right"> 
                    <div class="swiper-right-title">ExtroBox <span style="color:#333333">210</span></div>
                    <div class="swiper-right-cont">Extrobox210是南京中科逆熵科技有限公司基于寒武纪MLU220智能芯片开发的一款边缘端AI智能盒子。可提供优秀的AI算力和极低的整机功耗，更适用于智慧交通、智慧园区、智慧零售、智能制造、智能电网等边缘端智能应用场景。</div>
                </div>
            </div>
        </div>
    </div>
    <div class="comservices-main">
        <div class="main">
            <div class="main-top">
                <div class="main-top-single">
                    <div class="main-top-single-title"><img src="../assets/images/main_business/title-logo1.png" alt="" class="single-img"><div class="single-title">MLU220边缘方案优势</div></div>
                    <div class="main-top-single-cont"><div class="pointlogo"></div>  <div>数据本地智能处理，脱敏上传。</div></div>
                    <div class="main-top-single-cont"><div class="pointlogo"></div>  <div>数据实时分析，结果即时反馈。</div></div>
                    <div class="main-top-single-cont"><div class="pointlogo"></div>  <div>数据边缘清洗，智能分发，有效上传。</div></div>
                </div>
                <div class="main-top-single">
                    <div class="main-top-single-title"><img src="../assets/images/main_business/title-logo2.png" alt="" class="single-img"><div class="single-title">高性能、低功耗</div></div>
                    <div class="main-top-single-cont"><div class="pointlogo"></div>  <div>仅标准信用卡2/3大小,AI算力高达16.0Tops。</div></div>
                    <div class="main-top-single-cont"><div class="pointlogo"></div>  <div>满载功耗不到20W。</div></div>
                    <div class="main-top-single-cont"><div class="pointlogo"></div>  <div>可提供多达八路的全高清1080P视频编解码。</div></div>
                </div>
                <div class="main-top-single">
                    <div class="main-top-single-title"><img src="../assets/images/main_business/title-logo3.png" alt="" class="single-img"><div class="single-title">X86架构</div></div>
                    <div class="main-top-single-cont" style="line-height:0.28rem"><div class="pointlogo"></div>  <div>兼容性强,可运行几乎所有操作系统和软件。</div></div>
                    <div class="main-top-single-cont" style="line-height:0.28rem"><div class="pointlogo"></div>  <div>处理能力强,适用于高计性能的计算任务。</div></div>
                    <div class="main-top-single-cont" style="line-height:0.28rem"><div class="pointlogo"></div>  <div>多线程处理能力。 </div></div>
                    <div class="main-top-single-cont" style="line-height:0.28rem"><div class="pointlogo"></div>  <div>生态系统完善，可供选择性高。</div></div>
                </div>
            </div>
            <div class="main-mid">
                <div class="title">产品规格</div>
                <div class="main_product">
                    <div class="main_product_top">
                        <div class="main-mid_title">核心配置</div>
                        <div class="product_top_cont">
                            <div class="product_top_cont_single">
                                <div class="single_title">处理器</div>  
                                <div style="display: flex;justify-content: left;margin-bottom:0.2rem"><div >CPU：</div><div><div >集成Intel®Celeron 3865U</div><div>1.8GHz双核2线程处理器</div></div></div> 
                                <div  style="display: flex;justify-content: left;"><div>NPU：</div><div><div>MLU220</div><div>支持INT16、INT8 可达8.0Tops</div></div></div>
                            </div>
                            <div class="product_top_cont_single">
                                <div class="single_title">内存卡/加速卡</div>  
                                <div style="margin-bottom:0.2rem">LPDDR4X  4G  </div>
                                <div>Ebox202-M.2卡 </div>
                            </div>
                            <div class="product_top_cont_single">
                                <div class="single_title">电源</div>  
                                <div style="margin-bottom:0.2rem">直流电源</div>
                                <div>最大输入电压：12V</div>
                                <div>最大输入电流：4A</div>
                            </div>
                            <div class="product_top_cont_single">
                                <div class="single_title">功耗</div>  
                                <div style="margin-bottom:0.2rem">8.7W</div>
                                <div>Ebox202-M.2卡 </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="main_product_bottom">
                        <div class="main-mid_title">编解码能力</div>
                        <div class="product_top_cont" style="margin-bottom:0.2rem">
                            <div class="product_top_cont_single">
                                <div class="single_title">视频解码能力</div>  
                                <div>8路*1080@30p；支持H.264  H265</div>
                            </div>
                            <div class="product_top_cont_single">
                                <div class="single_title">图像解码能力</div>  
                                <div>410fps@FHD，支持标准JPEG解码</div>
                            </div> 
                            <div class="product_top_cont_single">
                                <div class="single_title">视频编码能力</div>  
                                <div>4路*1080P @30fps；支持H.264  H265</div>
                            </div>
                            <div class="product_top_cont_single">
                                <div class="single_title">图像解码能力</div>  
                                <div>400fps@FHD，支持标准JPEG解码</div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-bottom">
                <div class="title">应用场景</div>
                <img src="../assets/images/main_business/foot2.png" alt="" class="foot-img">
            </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
    data () {
        return {
            changeTabs: 1
        }
    },
    methods: {
     
    }
}
</script>
<style lang="scss" >
.main-mid .el-tab-pane{
    display: flex;
    justify-content: space-between;
}
.banner-img{
    width: 100%;
    height: 2.8rem;
    position: relative;
    background-size: cover;
}
</style>
<style lang="scss" >
.comservices {
  .bannerImg {
    height: 2.6rem;
  }
  .banner {
    left: 18.75%;
    transform: translateY(-60%);
    right: 34%;
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    p:nth-child(1) {
      display: inline-block;
      line-height: 0.2rem;
      font-size: 0.4rem;
    }
    p:nth-child(2) {
        font-size: 0.2rem;
        margin-top: 0.16rem;
        display: block;
        border-bottom: none;
    }
  }
  .swiper-banner{
    height: 3.8rem;
    background-image: url('../assets/images/main_business/swiper-bc.png');
        .swiper{
            width: 12rem;
            margin:0 auto;
            position: relative;
            .tabs{
                display: flex;
                justify-content: left;
                font-size: 0.16rem;
                font-family: Source Han Sans CN;
                text-align: center;
                padding-top: 0.27rem;
                .tabs-actived{
                    width: 1.3rem;
                    height: 0.42rem;
                    background: #0061E2;
                    border: 0.01rem solid #015EDC;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-right: 0.2rem;
                    padding:0 0.1rem;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    .text{
                        border-left: 0.03rem solid #FFFFFF;
                        height: 0.16rem;
                        line-height: 0.16rem;
                        padding-left: 0.14rem;
                    }
                }
                .tabs-active{
                    line-height: 0.42rem;
                    width: 1.3rem;
                    height: 0.42rem;
                    background: #F5F5F5;
                    border: 0.01rem solid #015EDC;
                    font-weight: bold;
                    color: #0061E3;
                    margin-right: 0.2rem;
                    padding:0 0.1rem;
                    cursor: pointer;
                }
            }
            .swiper1{
                position: absolute;
                top:1.21rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .swiper-img{
                    width: 4.83rem;
                    height: 2.02rem;
                }
                .swiper-right{
                    width: 6.01rem;
                    margin-left: 0.97rem;

                    .swiper-right-title{
                        font-size: 0.36rem;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #0061E3; 
                        margin-bottom: 0.3rem;
                    }
                    .swiper-right-cont{
                        font-size: 0.14rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }  
        }
   }
  .comservices-main{
      width: 100%;
      padding:0.6rem 0;
      background: #F5F5F5;
      .main{
          width:12rem;
          margin:0 auto;
          .main-top{
              display: flex;
              justify-content: space-between;
              .main-top-single{
                    width: 29%;
                    border: 1px solid #C8D5E1;
                    padding:0.3rem 0 0.3rem 0.3rem;
                    font-size: 0.14rem;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                    line-height: 0.34rem;
                  .main-top-single-title{
                      display: flex;
                      justify-content: left;
                      align-items: center;
                      font-size: 0.16rem;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      color: #0061E3;
                      margin-bottom: 0.15rem;
                      .single-img{
                        width: 0.24rem;
                        height: 0.25rem;
                      }
                      .single-title{
                        padding-left: 0.2rem;
                      }
                  }
                .main-top-single-cont{
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    .pointlogo{
                        width: 0.08rem;
                        height: 0.08rem;
                        background: #C8D5E1;
                        border-radius: 0.02rem;
                        margin:0 0.28rem 0 0.08rem
                    }
                }  
              }
          }
        }
        .main-mid{
            margin-top: 0.6rem;
           .title{  
                font-size: 0.32rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                text-align:center;
                margin-bottom: 0.21rem;
           } 
            .main-mid_title{
                padding-left: 0.07rem;
                font-size: 0.18rem;
                line-height: 0.24rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                border-left: 0.03rem solid #0061E3;
                margin-bottom: 0.2rem;
            }
            .main_product{
                border: 0.01rem solid #CFD3DA;
                .main_product_top{
                    padding:0.2rem;
                    border-bottom:0.01rem solid #CFD3DA;
                }
                .main_product_bottom{
                    padding:0.2rem;
                }
            }
        }
        .main-bottom{
            margin-top: 0.6rem;
           .title{  
                font-size: 0.32rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                text-align:center;
                margin-bottom: 0.21rem;
           }
           .foot-img{
               height: 3.6rem;
               width: 100%;
           } 
        }
    }
}
.product_top_cont{
    display: flex;
    justify-content: space-between;
    .product_top_cont_single{
        font-size: 0.14rem;
        color: #666666;
        width: 24%;
        .single_title{
            font-size: 0.14rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            margin-bottom: 0.2rem;
        }
    }
}
</style>
